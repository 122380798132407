/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */



$(document).ready(function () {

    $(".container-small .job-option .job-option-title").click(function () {
        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
        } else {
            $(this).addClass("active");
        }
    });
    try {
        if (window.location.href.indexOf('://') >= 0) {
            this.link = (window.location.href.substring(window.location.href.indexOf('://') + 3)).split('/');
        }

        // ---- TEMP
        if ($('#incgal-container').length > 0) {
            fullscrSlider.init($('#myCarousel'));

//            incOwlCarousel.init(owl, data);
//            $('#incgal-container').setElements();
        }

        if ($('.product-presentation') !== undefined && typeof product === 'object') {
            product.init($('.product-presentation'));
        }

            menu.init($('#menu-content'));

         if ($('#normas-content') !== undefined && typeof normas === 'object') {
            normas.init($('#normas-content'));
        }
         if ($('#rh-content') !== undefined && typeof rh === 'object') {
            rh.init($('#rh-content'));
        }
        if ($('#download-content') !== undefined && typeof download === 'object') {
            download.init($('#download-content'));
        }
//        console.log($('#news-content'));
        if ($('#news-content') !== undefined && typeof news === 'object') {
//            console.log("bbbb");
            news.init($('#news-content'));
        }


        if ($('#simboloy-content') !== undefined && typeof symbol === 'object') {
            symbol.init($('#simboloy-contentt'));
        }

    } catch (e) {
        console.log('[Scripts]: ' + e);
    }

});

   